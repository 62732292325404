import $ from "jquery";
import "../css/login.css";

function Element(tagName, attrs) {
	// generate jQuery-wrapped DOM without incurring the regex cost in the jQuery constructor
	var attr, val;
	var svgTagNames = ['svg','g','polygon','rect','circle','path','line','text','radialGradient','linearGradient','stop','filter','feGaussianBlur','feOffset','feMerge','feMergeNode','feColorMatrix','feComponentTransfer','feFuncA','feFlood','feComposite'];
	var element = svgTagNames.indexOf(tagName) !== -1 ? document.createElementNS('http://www.w3.org/2000/svg', tagName) : (tagName ? document.createElement(String(tagName)) : document.createDocumentFragment());
	if (tagName==='svg' && !attrs.focusable) element.setAttribute('focusable', false);//by default mark svg as non-focusable
	if (attrs) {
		for (attr in attrs) {
			if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
				val = attrs[attr];
				if (attr === 'text') {
					if (val !== null) element.appendChild(document.createTextNode(val));
				}
				else if (attr === 'children') {
					if (val !== null) val.forEach(append);
				}
				else if (attr === 'html') element.innerHTML = val;
				else element.setAttribute(attr, val);
			}
		}
	}
	function append(child) {element.appendChild(child)}
	var jQElement = $(element);
	var nodeName = element.nodeName;
	if ((nodeName==='SPAN' || nodeName==='DIV') && jQElement.prop('contenteditable')==="true" && element.hasAttribute('maxLength')) {
		jQElement.on("keypress", function() {
			return this.innerHTML.length < this.getAttribute("maxLength");
		}).on("paste", function(e) {
			e.preventDefault();//Insert as plain text
			var limit = this.getAttribute("maxLength"), len = this.innerHTML.length, cp;
			if ((e.originalEvent || e).clipboardData || window.clipboardData) {
				cp = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text');
			}
			if (cp && cp!=='' && len<=limit) {
				var text = cp.substring(0, limit - len);
				if (text && text!=='') {
					if (document.queryCommandSupported('insertText')) {
						document.execCommand('insertText', false, text);
					}
					else if (document.getSelection()) {
						document.getSelection().getRangeAt(0).insertNode(document.createTextNode(text));
					}
					else if (document.selection) {
						document.selection.createRange().pasteHTML(text);
					}
				}
			}
			return false;
		});
	}
	return jQElement;
}
function renderRevealPasswordFor(field) {
	var visible=false, span;
	return span=Element('span', {class:'showPassword'}).append(
		eyeReveal()
	).click(function() {
		visible= !visible;
		if (visible) {
			field.attr('type', 'text');
			span.empty().append(eyeHide());
		}
		else {
			field.attr('type', 'password');
			span.empty().append(eyeReveal());
		}
	});
	function eyeReveal() {
		return Element('svg', {xmlns:'http://www.w3.org/2000/svg'}).append(
			Element('path', {fill:"#c25608", d:"M14.9 7.35c0.2 0.25 0.3 0.5 0.3 0.75s-0.1 0.45-0.3 0.6c-0.15 0.1-0.35 0.1-0.55 0.050-0.25-0.050-0.45-0.15-0.6-0.35-0.4-0.45-0.85-0.85-1.35-1.2-0.45-0.3-0.95-0.6-1.5-0.8 0.15 0.25 0.25 0.5 0.35 0.8s0.15 0.6 0.15 0.9c0 0.5-0.1 0.95-0.3 1.4s-0.45 0.8-0.75 1.15c-0.35 0.35-0.7 0.6-1.15 0.8s-0.9 0.3-1.4 0.3c-0.5 0-0.95-0.1-1.4-0.3s-0.8-0.45-1.15-0.8c-0.3-0.35-0.55-0.7-0.75-1.15s-0.3-0.9-0.3-1.4c0-0.3 0-0.55 0.050-0.8s0.1-0.5 0.2-0.8c-0.4 0.25-0.85 0.5-1.25 0.8s-0.8 0.6-1.1 1c-0.1 0.2-0.3 0.3-0.5 0.4-0.2 0.050-0.4 0.050-0.6-0.1-0.15-0.1-0.2-0.25-0.2-0.5s0.1-0.5 0.3-0.75c0.9-1 2-1.8 3.2-2.3s2.45-0.75 3.7-0.75c1.25 0 2.5 0.25 3.7 0.75 1.25 0.5 2.3 1.25 3.2 2.3zM8.25 8.4c0.3-0.3 0.45-0.65 0.4-1.1 0-0.4-0.15-0.8-0.45-1.050-0.3-0.3-0.65-0.45-1.1-0.45-0.4 0-0.8 0.15-1.050 0.45-0.3 0.3-0.45 0.65-0.45 1.050s0.15 0.8 0.45 1.1c0.3 0.3 0.65 0.45 1.050 0.45 0.5 0 0.85-0.15 1.15-0.45z"})
		);
	}
	function eyeHide() {
		return Element('svg', {xmlns:'http://www.w3.org/2000/svg'}).append(
			Element('path', {fill:"#c25608", d:"M14.35 1.75c0.15 0.2 0.2 0.45 0.2 0.75s-0.15 0.55-0.4 0.8l-11.2 11.3c-0.2 0.2-0.45 0.3-0.7 0.35-0.3 0.050-0.55-0.050-0.7-0.25-0.2-0.15-0.3-0.35-0.25-0.65 0-0.3 0.15-0.55 0.35-0.8l2.95-2.95c-0.15-0.25-0.25-0.5-0.35-0.8s-0.15-0.6-0.15-0.95c0-0.3 0-0.55 0.050-0.8s0.1-0.5 0.2-0.75c-0.45 0.25-0.85 0.5-1.25 0.8s-0.8 0.65-1.15 1c-0.2 0.15-0.4 0.25-0.6 0.3s-0.4 0.050-0.55-0.050c-0.2-0.15-0.25-0.35-0.25-0.6 0.050-0.25 0.15-0.45 0.35-0.65 0.55-0.65 1.2-1.2 1.9-1.65s1.4-0.8 2.2-1c0.75-0.25 1.55-0.4 2.35-0.4 0.8-0.050 1.6 0.050 2.4 0.2l3.050-3.050c0.25-0.25 0.5-0.35 0.8-0.4 0.3 0.050 0.55 0.1 0.75 0.25zM5.45 7.8c0 0.25 0.050 0.45 0.15 0.6s0.2 0.3 0.35 0.45l2.2-2.2c-0.15-0.1-0.3-0.2-0.5-0.25-0.2-0.1-0.35-0.1-0.55-0.1-0.45 0-0.8 0.15-1.15 0.45-0.35 0.25-0.5 0.6-0.5 1.050zM11.3 8.15c0 0.050 0 0.1 0 0.2s0 0.2 0 0.3c0 0.5-0.1 0.95-0.3 1.4s-0.45 0.85-0.8 1.15c-0.35 0.35-0.7 0.6-1.15 0.8s-0.9 0.3-1.4 0.3c-0.1 0-0.2 0-0.25-0.050s-0.15-0.050-0.25-0.050l4.15-4.050zM15.15 8.55c0.050 0.25-0.050 0.45-0.25 0.6-0.15 0.1-0.3 0.1-0.5 0.050s-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-0.55-0.85-0.8-0.3-0.25-0.6-0.45-0.95-0.65l1.050-1.050c0.3 0.2 0.6 0.4 0.9 0.65s0.6 0.55 0.85 0.9c0.1 0.15 0.25 0.35 0.25 0.6z"})
		);
	}
}
function getUrlParam(parameter, defaultvalue) {
	var urlparameter=defaultvalue;
	if (window.location.href.indexOf(parameter)> -1) {
		urlparameter=getUrlVars()[parameter];
	}
	return urlparameter;
}
function getUrlVars() {
	var vars={};
	var parts=window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
		vars[key]=value;
	});
	return vars;
}
function getAlertIcon() {
	return Element('svg', {viewBox:"0 0 16 16"}).append(
		Element('path', {fill:"#e32315", d:"M8.1.85C4.2.85 1.05 4 1.05 7.9s3.15 7.05 7.05 7.05 7.05-3.15 7.05-7.05S12 .85 8.1.85zm.65 11.8c-.2.15-.4.25-.65.25s-.5-.1-.7-.25c-.2-.15-.3-.4-.3-.7 0-.25.1-.5.3-.65.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.65-.05.25-.15.5-.35.7zM9 5.55L8.75 8.7c-.05.35-.1.65-.2.85s-.25.3-.5.3-.4-.1-.5-.3-.15-.5-.2-.9l-.2-3.05c-.05-.6-.05-1-.05-1.3 0-.35.1-.65.3-.85s.45-.3.75-.3c.35 0 .6.15.75.4.1.25.2.6.2 1.1-.05.3-.05.6-.1.9z"})
	);
}
jQuery.fn.extend({
	focusAfter: function() {
		return this.each(function(index, element) {
			setTimeout(function() {jQuery(element).focus()}, 100);
		});
	}
});

export {
	Element,
	renderRevealPasswordFor,
	getUrlParam,
	getUrlVars,
	getAlertIcon
}